type ClassNameValue = string | undefined | null | false;

const tw =
  (prefix: string) =>
  (...args: ClassNameValue[]): string => {
    return args
      .join(" ")
      .split(" ")
      .filter(Boolean)
      .map((cn) => `${prefix}-${cn}`)
      .join(" ");
  };

const always = (...args: ClassNameValue[]): string => {
  return args.join(" ").split(" ").filter(Boolean).join(" ");
};

const maybe = (enabled: unknown, ...className: ClassNameValue[]): string => {
  return !!enabled ? className.filter(Boolean).join(" ") : "";
};

const toggle = (
  enabled: unknown,
  className1: ClassNameValue | ClassNameValue[],
  className2: ClassNameValue | ClassNameValue[],
): string => {
  const one = Array.isArray(className1)
    ? className1.filter(Boolean).join(" ")
    : className1 || "";
  const two = Array.isArray(className2)
    ? className2.filter(Boolean).join(" ")
    : className2 || "";
  return !!enabled ? one : two;
};

export function generateClassNameHelpers(prefix?: string) {
  return {
    tw: tw(prefix || ""),
    always,
    maybe,
    toggle,
  };
}
