import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ComponentType,
  forwardRef,
  ReactNode,
  useContext,
} from "react";
import { a } from "kremling";
import { CpSideMenuContext } from "../cp-side-menu-context";
import { CpIconDynamic } from "../../cp-icon/cp-icon.component";
import { NavLink, NavLinkProps } from "react-router-dom";
import {
  CpSideMenuAccordionButtonContext,
  CpSideMenuAccordionContext,
} from "./cp-side-menu-accordion-context";
import { RenderIconDynamic } from "../../cp-icon/render-icon-dynamic";

type CpSideMenuAsButton = {
  as?: "button";
} & ButtonHTMLAttributes<HTMLButtonElement>;
type CpSideMenuAsAnchor = {
  as?: "a";
} & AnchorHTMLAttributes<HTMLAnchorElement>;
type CpSideMenuAsNavLink = { as?: typeof NavLink; className?: string } & Omit<
  NavLinkProps,
  "className"
>;

type CpSideMenuAs =
  | CpSideMenuAsButton
  | CpSideMenuAsAnchor
  | CpSideMenuAsNavLink;

export type CpSideMenuButtonProps = CpSideMenuAs & {
  id?: string | number;
  children?: ReactNode;
  iconLeft?: CpIconDynamic;
  iconRight?: CpIconDynamic;
};

export const CpSideMenuButton = forwardRef<HTMLElement, CpSideMenuButtonProps>(
  function CpSideMenuButton(props, ref) {
    const {
      className,
      id,
      as = "button",
      children,
      iconLeft,
      iconRight,
      onClick,
      ...restProps
    } = props;
    const { value, onChange, setActiveAccordion } =
      useContext(CpSideMenuContext);
    const { accordionId: childId } = useContext(CpSideMenuAccordionContext);
    const { isActive: activeAccordion, accordionId: parentId } = useContext(
      CpSideMenuAccordionButtonContext,
    );
    const accordionId = childId || parentId;
    const isSubItem = !!childId;
    const isActive = (id && id === value) || activeAccordion;
    const Wrapper: ComponentType<any> | string = as;

    const runClassNames = (routeIsActive?: boolean) => {
      return a(className)
        .m("cp-side-menu-button", !isSubItem)
        .m("cp-side-menu-sub", isSubItem)
        .m("cp-side-menu-button__active", isActive || routeIsActive);
    };

    return (
      <Wrapper
        data-cp-side-menu-button
        ref={ref}
        {...restProps}
        className={
          as === "button" || as === "a"
            ? runClassNames()
            : ({ isActive }: { isActive: boolean }) => runClassNames(isActive)
        }
        onClick={(e: Event) => {
          setActiveAccordion(accordionId);
          if (id) {
            onChange?.(id);
          }
          onClick?.(e as any);
        }}
      >
        <div className="cp-side-menu__left">
          {iconLeft && (
            <div className="cp-side-menu-button__icon-left">
              <RenderIconDynamic icon={iconLeft} />
            </div>
          )}
          <div className="cp-side-menu-button__inner">{children}</div>
        </div>
        {iconRight && (
          <div className="cp-side-menu-button__icon-right">
            <RenderIconDynamic icon={iconRight} />
          </div>
        )}
      </Wrapper>
    );
  },
);
